import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
//import { PageLoader } from "redbox-components";

import PageLoader from './SharedComponents/PageLoader/PageLoader';

//import returnStoreAndPersistor from "./store/configureStore";
//const { store, persistor } = returnStoreAndPersistor();

import { store, persistor } from './store/configureStore'; // Importing store and persistor directly

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={<PageLoader />} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();


//"kekax-components": "git+https://npm_install_token:glpat-huvmimimimibdaddasdasd@git.bigplanes.com/kekax/react-shared-kekax-comp.git#v1.0.199",
